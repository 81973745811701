html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto,sans-serif;
  color: white;
  height: 100%; }

div#root, .container {
  height: 100%; }

[data-reactroot] {
  height: 100% !important; }
