.ADMIN {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  background: radial-gradient(#faf31e, #ed6f39);
  background: yellow;
  color: black;
  margin-left: 1%;
  margin-top: 1%;
  padding-left: 2.5px;
  padding-right: 2.5px;
  display: inline-block; }

.chat_user {
  padding-left: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: pointer; }

.chat_time {
  float: right;
  color: #8faeb7;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-right: 1%; }

.chat_message {
  width: 96%;
  padding-left: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  display: inline-block;
  color: #d7d7d7;
  word-break: break-all; }
